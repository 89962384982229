import { Model } from '@vuex-orm/core';
import { Category, CategoryModel } from './category';

export interface SubCategoryModel {
  _id?: string;
  name: string;
  category: CategoryModel;
  types: [];
  weight: number;
}

export class SubCategory extends Model {
  public static entity = 'subcategories';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(''),
      category: this.belongsTo(Category, 'category_id'),
      category_id: this.attr(''),
      types: this.attr(null),
      weight: this.attr(null),
    };
  }
}
