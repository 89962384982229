// Utilities
import { make } from 'vuex-pathify';

import { environment } from '@/env';

export enum LoadStatus {
  UNSET = 0,
  LOADED,
  LOADING,
}

export enum AppMode {
  therapistsView = 'therapistsView',
  clientView = 'clientView',
}

export enum CUSTOM_CURSOR_TYPE {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

export enum CURSOR_TYPE {
  POINTER = 'pointer',
  CIRCLE = 'circle',
}

export enum CURSOR_COLOR {
  YELLOW = 'yellow',
  GREEN = 'green',
  RED = 'red',
  PINK = 'pink',
  BLUE = 'blue',
  BLACK = 'black',
}

export enum CURSOR_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum CURSOR_APPEAR {
  ACTIVITY_ONLY = 'activity_only',
  ANYWHERE = 'anywhere',
}

export const clientViewPages = ['home-practice'];
// const [version, env] = ((document.getElementById('app-env') as HTMLMetaElement)?.content || '0.0.0-dev, dev').split(', ');

// export const global = {
//   url: window.location.href,
//   userAgent: window.navigator.userAgent,
//   mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent),
//   version,
//   env,
//   pwa: new URLSearchParams(window.location.search).get('utm_source') === 'PWA',
// };

const state = {
  currentVersion: null,
  drawer: null,
  isLoading: false,
  releases: [],
  metadata: LoadStatus.UNSET,

  hasToolbar: true,
  hasDrawer: true,
  hasFooter: true,
  hasSystemNotification: true,

  cursorSettings: {
    type: CUSTOM_CURSOR_TYPE.DEFAULT,
    cursorType: CURSOR_TYPE.POINTER,
    img: null,
    size: null,
    display: null,
  },

  userAgent: window.navigator.userAgent,

  mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent),
  pwa:
    window.matchMedia('(display-mode: standalone)').matches ||
    (window.navigator as any).standalone ||
    document.referrer.includes('android-app://'),
  // new URLSearchParams(window.location.search).get('utm_source') === 'PWA',

  version: environment.version,
  env: environment.env,
  appMode: AppMode.therapistsView,
  supportsAutoPlay: true,
};

const mutations = {
  ...make.mutations(state),
  updateAutoPlay(state, { autoPlay }) {
    state.supportsAutoPlay = autoPlay;
  },
};

const actions = {
  updateAutoPlay({ state, commit }, { payload }) {
    commit('updateAutoPlay', { autoPlay: payload.autoPlay });
  },
};

const getters = {
  getSupportAutoPlay(state) {
    return state.supportsAutoPlay;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
