import { Model } from '@vuex-orm/core';
import { ObjectID } from 'bson';
import { Activity } from './activity';

export class Strip extends Model {
  public static entity = 'strips';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(new ObjectID()),
      name: this.attr(''),
      // groups: this.attr([]),
      //      category: this.belongsTo(Category, 'category_id'),
      groups: this.hasMany(Group, 'group_id'),
      new: this.attr(null), // private
    };
  }
}

// tslint:disable-next-line: max-classes-per-file
export class Group extends Model {
  public static entity = 'groups';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      group_id: this.attr(null),
      languageCode: this.attr([]),
      name: this.attr(null),
      items: this.attr([]),
      activities: this.hasManyBy(Activity, 'items'),
    };
  }
}
