import {
  VBottomSheet,
  VSimpleTable,
  VTextarea,
  Ripple,
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAutocomplete,
  VAvatar,
  VBtn,
  VBtnToggle,
  VCheckbox,
  VSimpleCheckbox,
  VMain,
  VContent,
  VCombobox,
  VDialog,
  VDivider,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VFooter,
  VForm,
  VContainer,
  VSpacer,
  VIcon,
  VImg,
  VInput,
  VLazy,
  VListItemContent,
  VListItemTitle,
  VListItemSubtitle,
  VList,
  VListItem,
  VListItemAction,
  VListItemGroup,
  VMenu,
  VNavigationDrawer,
  VOverflowBtn,
  VOverlay,
  VProgressCircular,
  VProgressLinear,
  VRadioGroup,
  VRadio,
  VRating,
  VResponsive,
  VSelect,
  VSnackbar,
  VSwitch,
  VSystemBar,
  VTabs,
  VTab,
  VTabItem,
  VTabsItems,
  VTextField,
  VToolbar,
  VToolbarItems,
  VToolbarTitle,
  VFadeTransition,
  VCol,
  VRow,
  Intersect,
  VSlideGroup,
  VSlideItem,
  VBadge,
  VStepper,
  VStepperHeader,
  VStepperContent,
  VStepperItems,
  VStepperStep,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
} from 'vuetify/lib';

export default {
  directives: {
    Ripple,
    Intersect,
  },
  components: {
    VBottomSheet,
    VTextarea,
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAutocomplete,
    VAvatar,
    VBtn,
    VBtnToggle,
    VCheckbox,
    VSimpleCheckbox,
    VMain,
    VContent,
    VCombobox,
    VDialog,
    VDivider,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VFooter,
    VForm,
    VContainer,
    VSpacer,
    VIcon,
    VImg,
    VInput,
    VLazy,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
    VList,
    VListItem,
    VListItemAction,
    VListItemGroup,
    VMenu,
    VNavigationDrawer,
    VOverflowBtn,
    VOverlay,
    VProgressCircular,
    VProgressLinear,
    VRadioGroup,
    VRadio,
    VRating,
    VResponsive,
    VSelect,
    VSnackbar,
    VSwitch,
    VSystemBar,
    VTabs,
    VTab,
    VTabItem,
    VTabsItems,
    VTextField,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
    VFadeTransition,
    VSimpleTable,
    VRow,
    VCol,
    VSlideGroup,
    VSlideItem,
    VBadge,
    VStepper,
    VStepperHeader,
    VStepperContent,
    VStepperItems,
    VStepperStep,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
  },
};
