import { Model } from '@vuex-orm/core';

export class UserDefinedActivity extends Model {
  public static entity = 'user-generated-activities';

  public static primaryKey = '_id';
  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(null),
      languageCode: this.attr(''),
      type: this.attr(null),
      punctuatedText: this.attr(null),
      data: this.attr([]),
      activityInfo: this.attr(null),
      createdBy: this.attr(null),
    };
  }
}
