import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@/env';
import { useState } from '@u3u/vue-hooks';

const dataDogRumInit = (extraOptions: object = {}) => {
  datadogRum.init({
    applicationId: '57ead5a0-9b7a-4ea3-83b0-74cae7768799',
    clientToken: 'pub4e41d9ee774705e2a4665e0bacb19abd',
    site: 'datadoghq.eu',
    service: 'cognishine-ci',
    env: environment.env,
    version: environment.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    ...extraOptions,
  });

  console.log('data dog inshrine with', { env: environment.env, version: environment.version, service: 'cognishine-ci' });
};

const dataDogRumSetUser = (user: { _id: string; name: string; email: string; userExtraInfo?: object }) => {
  // const { user } = useState('auth', ['user']);

  datadogRum.setUser({
    id: user?._id,
    name: user?.name,
    email: user?.email,
    userExtraInfo: {
      preferences: (user as any)?.preferences,
      lastSelectedClientId: (user as any)?.lastSelectedClientId,
      verified: (user as any)?.verified,
      active: (user as any)?.active,
      roles: (user as any)?.roles,
      organization: (user as any)?.organization,
    },
  });
};

const dataDogRumClearUser = () => datadogRum.clearUser();

export { dataDogRumInit, dataDogRumSetUser, dataDogRumClearUser };
