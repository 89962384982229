import { serializeError, deserializeError } from 'serialize-error';
import axios from 'axios';
import Vue from 'vue';
import { environment } from '@/env';

const [version, env] = ((document.getElementById('app-env') as any)?.content || '0.0.0-dev, dev').split(', ');

export const global = {
  url: window.location.href,
  userAgent: window.navigator.userAgent,
  mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent),
  version,
  env,
  pwa:
    window.matchMedia('(display-mode: standalone)').matches ||
    (window.navigator as any).standalone ||
    document.referrer.includes('android-app://'),

  // pwa: new URLSearchParams(window.location.search).get('utm_source') === 'PWA',
};

if (process.env.NODE_ENV === 'production') {
  window.onerror = (message, source, lineno, colno, error) => {
    let err = error;

    if (!err) {
      err = new Error(message as string);
      err.stack = `string-error: ${source} ${lineno}:${colno}`;
    }

    console.log('--- FATAL ERROR ---');
    console.error(message);
    console.error(err);

    const exception = serializeError(err);

    global.url = window.location.href;

    axios({
      url: `/api/collect`,
      method: 'post',
      data: {
        error: true,
        exception,
        message,
        meta: { fatal: true, message, source, lineno, colno, ...global },
      },
    });
  };

  Vue.config.errorHandler = (error, vm, info) => {
    console.log({ vm });

    const message = info;
    const err = error;

    console.log('--- ERROR ---');
    console.error(message);
    console.error(err);

    const exception = serializeError(err);

    global.url = window.location.href;

    axios({
      url: `/api/collect`,
      method: 'post',
      data: {
        error: true,
        exception,
        message,
        meta: { message, ...global },
      },
    });
  };
}
