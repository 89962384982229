import { render, staticRenderFns } from "./switch-client.component.html?vue&type=template&id=61b1e1d3&scoped=true&external"
import script from "./switch-client.component?vue&type=script&lang=ts&external"
export * from "./switch-client.component?vue&type=script&lang=ts&external"
import style0 from "./switch-client.component.scss?vue&type=style&index=0&id=61b1e1d3&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b1e1d3",
  null
  
)

export default component.exports