import { ALLOWED_LANGUAGES } from '@packages/mongodb';

export enum entityPerLanguages {
  ARTICULATION_AUDIO_COMPONENT = 'articulation_audio_component',
  ARTICULATION_AUDIO_COMPONENT_TOOLTIP = 'articulation_audio_component_tooltip',
  ARTICULATION_AUDIO_COMPONENT_BUTTON = 'articulation_audio_component_button',
  COMPREHENSION_OR_IDENTIFYING_EMOTIONS_COMPONENT = 'comprehension_or_identifying_emotions_component',
  COMPREHENSION_OR_IDENTIFYING_EMOTIONS_COMPONENT_TOOLTIP = 'comprehension_or_identifying_emotions_tooltip',
  COMPREHENSION_OR_IDENTIFYING_EMOTIONS_COMPONENT_BUTTON = 'comprehension_or_identifying_emotions_button',
  ARTICULATION_LAYOUT_LAYOUT_COMPONENT_NOT_ACTIVE = 'articulation_layout_layout_component_not_active',
  DOMAINS_LAYOUT_LAYOUT_NOT_ACTIVE = 'domains_layout_layout_not_active',
  FREE_ACTIVITIES_COMPONENT_SHOW_ARTICULATION = 'free_activities_component_show_articulation',
  HELPERS_GET_PRICING_URL = 'helpers_get_pricing-url',
  CLALIT_LOGIN = 'clalit_login',
  PLAN_PRICING_ROUTE_ACCESS = 'plan_pricing_route_access',
  HOME_LAYOUT_COMPONENT_SHOW_ARTICULATION = 'home_layout_component_show_articulation',
  ARTICULATION_VOWELS_PAGE_ACCESS = 'articulation_vowels_page_access',
  SPEECH_DOMAIN_STANDARD_SUBDOMAIN_CARD = 'speech_domain_standard_subdomain_card',
}

// Value of Object key only contains Array. and It allowed only language in which that feature is available.
export const setAccessPerLanguage = {
  [entityPerLanguages.ARTICULATION_AUDIO_COMPONENT]: { forNoneAdminUser: [ALLOWED_LANGUAGES.ar] },
  [entityPerLanguages.ARTICULATION_AUDIO_COMPONENT_TOOLTIP]: { forNoneAdminUser: [ALLOWED_LANGUAGES.ar] },
  [entityPerLanguages.ARTICULATION_AUDIO_COMPONENT_BUTTON]: { forNoneAdminUser: [ALLOWED_LANGUAGES.ar] },
  [entityPerLanguages.COMPREHENSION_OR_IDENTIFYING_EMOTIONS_COMPONENT]: { forNoneAdminUser: [ALLOWED_LANGUAGES.ar] },
  [entityPerLanguages.COMPREHENSION_OR_IDENTIFYING_EMOTIONS_COMPONENT_TOOLTIP]: { forNoneAdminUser: [ALLOWED_LANGUAGES.ar] },
  [entityPerLanguages.COMPREHENSION_OR_IDENTIFYING_EMOTIONS_COMPONENT_BUTTON]: { forNoneAdminUser: [ALLOWED_LANGUAGES.ar] },
  [entityPerLanguages.ARTICULATION_LAYOUT_LAYOUT_COMPONENT_NOT_ACTIVE]: {
    forNoneAdminUser: [
      ALLOWED_LANGUAGES.en,
      ALLOWED_LANGUAGES.he,
      ALLOWED_LANGUAGES.ar,
      ALLOWED_LANGUAGES.de,
      ALLOWED_LANGUAGES.be,
      ALLOWED_LANGUAGES.ru,
      ALLOWED_LANGUAGES.es,
      ALLOWED_LANGUAGES.tr,
    ],
  },
  [entityPerLanguages.DOMAINS_LAYOUT_LAYOUT_NOT_ACTIVE]: {
    forNoneAdminUser: [
      ALLOWED_LANGUAGES.en,
      ALLOWED_LANGUAGES.he,
      ALLOWED_LANGUAGES.ar,
      ALLOWED_LANGUAGES.de,
      ALLOWED_LANGUAGES.be,
      ALLOWED_LANGUAGES.ru,
      ALLOWED_LANGUAGES.es,
      ALLOWED_LANGUAGES.tr,
    ],
  },
  [entityPerLanguages.FREE_ACTIVITIES_COMPONENT_SHOW_ARTICULATION]: {
    forNoneAdminUser: [ALLOWED_LANGUAGES.en, ALLOWED_LANGUAGES.he, ALLOWED_LANGUAGES.ar, ALLOWED_LANGUAGES.be, ALLOWED_LANGUAGES.ru],
  },
  //
  [entityPerLanguages.HELPERS_GET_PRICING_URL]: {
    forNoneAdminUser: [ALLOWED_LANGUAGES.en, ALLOWED_LANGUAGES.de, ALLOWED_LANGUAGES.be, ALLOWED_LANGUAGES.es, ALLOWED_LANGUAGES.tr],
  },
  [entityPerLanguages.CLALIT_LOGIN]: {
    forNoneAdminUser: [ALLOWED_LANGUAGES.he, ALLOWED_LANGUAGES.ar, ALLOWED_LANGUAGES.be, ALLOWED_LANGUAGES.ru],
  },
  [entityPerLanguages.PLAN_PRICING_ROUTE_ACCESS]: {
    forNoneAdminUser: [ALLOWED_LANGUAGES.en, ALLOWED_LANGUAGES.de, ALLOWED_LANGUAGES.be, ALLOWED_LANGUAGES.es, ALLOWED_LANGUAGES.tr],
  },
  [entityPerLanguages.HOME_LAYOUT_COMPONENT_SHOW_ARTICULATION]: {
    forNoneAdminUser: [ALLOWED_LANGUAGES.en, ALLOWED_LANGUAGES.ar, ALLOWED_LANGUAGES.he, ALLOWED_LANGUAGES.be, ALLOWED_LANGUAGES.ru],
  },
  [entityPerLanguages.ARTICULATION_VOWELS_PAGE_ACCESS]: {
    forNoneAdminUser: [
      ALLOWED_LANGUAGES.en,
      ALLOWED_LANGUAGES.de,
      ALLOWED_LANGUAGES.be,
      ALLOWED_LANGUAGES.ru,
      ALLOWED_LANGUAGES.es,
      ALLOWED_LANGUAGES.tr,
    ],
  },
  [entityPerLanguages.SPEECH_DOMAIN_STANDARD_SUBDOMAIN_CARD]: {
    forNoneAdminUser: [ALLOWED_LANGUAGES.en, ALLOWED_LANGUAGES.he, ALLOWED_LANGUAGES.ar, ALLOWED_LANGUAGES.be, ALLOWED_LANGUAGES.ru],
  },
};
