import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import libs from './vuetify-lib';
import VuetifyDialog from 'vuetify-dialog';
import VuetifyToast from './vuetify-toast-snackbar';

// custom icons import 
import switchClient from '@/components/core/custom-icon/switch-client';


Vue.use(Vuetify, libs);

export function createVuetify() {
  const vuetify = new Vuetify({
    // icons: {
    //   iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    // },

    breakpoint: {
      thresholds: {
        // xs: 0,
        // sm: 575,
        // md: 768,
        // lg: 1024,
        // xl: 1400,
        // xxl: 1600,
        // xxxl: 2000,
        xs: 576, // until is xs
        sm: 768,
        md: 1024 + 16,
        lg: 1400 + 16,
      },
    },
    icons: {
      values: {
        clientSwitch: {
          component: switchClient
        }
      }
    },
    theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          primary: '#363636',
          error: '#F44236',
          info: '#2580EB',
          warning: '#E3930A',
          success: '#02A388',
        },
        dark: {
          primary: '#363636',
          error: '#F44236',
          info: '#2580EB',
          warning: '#E3930A',
          success: '#02A388',
        },
      },
    },
    options: {
      minifyTheme: (css: any) => {
        return process.env.NODE_ENV === 'production' ? css.replace(/[\s|\r\n|\r|\n]/g, '') : css;
      },
    },
  });

  Vue.use(VuetifyDialog, {
    context: {
      vuetify,
    },
  });

  Vue.use(VuetifyToast, {
    x: 'center',
    y: 'top',
    timeout: 5 * 1000,
    showClose: false,
    closeColor: '#fff',
    closeIcon: 'mdi-close',
  });

  return vuetify;
}
