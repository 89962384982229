import SecureLS from 'secure-ls';
import { EncryptStorage } from 'encrypt-storage';

const ls = new SecureLS({ encodingType: 'aes' });

const ss = new EncryptStorage('secret-key-value', { storageType: 'sessionStorage' });

export enum StorageTypes {
  USER = 'cu',
  TOKEN = 'ct',
  KEEP_LOGIN = 'ckl',
  REDIRECT_LOGIN = 'crl',
  SHOW_VERIFY_USER_MESSAGE = 'svum',
  IS_CLALIT_USER = 'icu',
  CLALIT_USER_EMAIL = 'cue',
  LANG_WHEN_CLALIT_LOGIN_OPENED = 'lwclo',
  HOME_PRACTICE_BROWSER_ID = 'hpbi',
  USER_IDEA_TIME = 'csdc',
  BROWSER_ID = 'bi',

  HEART_BEAT_SESSION_ID = 'hbsid',
  PREVIOUS_USER_ID = 'pui',
  PREVIOUS_CLIENT_ID = 'pci',
  PREVIOUS_HOME_PRACTICE_ID = 'phpi',
  LAST_USER_INTERACTION_TIME = 'luit',
}

export const multiStorageTypeSupportedTypes = [
  StorageTypes.USER,
  StorageTypes.TOKEN,
  StorageTypes.HEART_BEAT_SESSION_ID,
  StorageTypes.PREVIOUS_USER_ID,
  StorageTypes.PREVIOUS_CLIENT_ID,
  StorageTypes.PREVIOUS_HOME_PRACTICE_ID,
  StorageTypes.LAST_USER_INTERACTION_TIME,
];

export const useStorage = () => {
  const destroy = (key: string) => {
    console.log('destroy : ', key);
    if (multiStorageTypeSupportedTypes.includes(key as StorageTypes)) {
      ls.remove(key);
      ss.removeItem(key);
      return;
    }
    ls.remove(key);
  };

  const get = (key: StorageTypes) => {
    try {
      const item = multiStorageTypeSupportedTypes.includes(key) ? JSON.stringify(ss.getItem(key)) || ls.get(key) : ls.get(key);
      return item && JSON.parse(item);
    } catch (e) {
      console.log('destroy in catch : ', e);
      destroy(key);
    }
  };

  const save = (key: StorageTypes, item: any, storeInSession = false) => {
    if (multiStorageTypeSupportedTypes.includes(key)) destroy(key);

    storeInSession ? ss.setItem(key, JSON.stringify(item)) : ls.set(key, JSON.stringify(item));
  };

  return { get, save, destroy };
};
