import { useStorage, StorageTypes } from '@/services/storage';
import {
  isBasicOrgPlan,
  isProOrgPlan,
  isUserHasOrganization,
  isUserCanVisitOrganization,
  isUserHasOrgAdminRole,
  isUserHasOrgAdminOrContentManagerRole,
} from '@/utils/organizationHelpers';
import { getLanguages } from '@/data/i18n/languages';

const { get, save, destroy } = useStorage();

const loadFromStorage = () => {
  const [user] = [get(StorageTypes.USER)];

  return user;
};

export const isUserNotLoggedInGuard = (to, from, next) => {
  const user = loadFromStorage();

  // FIXME: use isUserLoggedIn from helper like we did for other guards. also i tried to use that but for some reason when that function i was importing from helper in this file it was breaking project and when i added it in organizationHelpers same function works here.
  if (!(user && user !== null && user !== undefined)) {
    next();
  } else {
    next('/');
  }
};

export const isUserHasOrganizationGuard = (to, from, next) => {
  const user = loadFromStorage();

  if (isUserHasOrganization(user)) {
    next();
  } else {
    next('/');
  }
};

export const isUserHasBasicOrProPlanGuard = (to, from, next) => {
  const user = loadFromStorage();

  if (isBasicOrgPlan(user) || isProOrgPlan(user)) {
    next();
  } else {
    next('/');
  }
};

export const isUserHasProPlanGuard = (to, form, next) => {
  const user = loadFromStorage();

  if (isProOrgPlan(user)) {
    next();
  } else {
    next('/');
  }
};

export const isUserHasOrgAndUserNotHasOrgViewerRoleGuard = (to, from, next) => {
  const user = loadFromStorage();

  if (isUserCanVisitOrganization(user)) {
    next();
  } else {
    next('/404');
  }
};

export const isUserNavigatingWithClientContext = (to, from, next) => {
  const user = loadFromStorage();
  if (user?.lastSelectedClientId) {
    next();
  } else {
    next('/');
  }
};

export const isUserHasOrgAdminRoleGuard = (to, from, next) => {
  const user = loadFromStorage();
  if (isUserHasOrgAdminRole(user)) {
    next();
  } else {
    next('/');
  }
};

export const isNotPrivateWebsiteGaurd = (to, from, next) => {
  const availableLang = getLanguages();

  if (availableLang?.length > 0 && to?.params?.lang) {
    const isPrivateWebsite = !availableLang?.find((lang) => lang?.locale === to?.params?.lang)?.isPublic || false;
    if (isPrivateWebsite) {
      next(`${to?.params?.lang}/`);
    } else {
      next();
    }
  }
};

export const isUserHasContentManagementPageAccessGuard = (to, from, next) => {
  const user = loadFromStorage();
  const availableLang = getLanguages();
  const currentLang = availableLang?.find((lang) => lang?.locale === to?.params?.lang);

  const isWebsiteAccess = user?.organization?.websiteAccess?.includes(currentLang?._id);

  if (isWebsiteAccess && isUserHasOrgAdminOrContentManagerRole(user)) {
    next();
  } else {
    next(`${to?.params?.lang}/`);
  }
};
