import mongoose, { Document, Schema } from 'mongoose';
import { ALLOWED_LANGUAGES } from './activity';

export interface Language {
  languageCode: string;
  fallback: Boolean;
  rtl: Boolean;
  fontFamily: String;
}

export interface MongooseLanguage extends Language, Document {}

const LanguageSchema = new Schema(
  {
    languageCode: { type: String, enum: Object.values(ALLOWED_LANGUAGES) },
    fallback: { type: Boolean, default: false },
    rtl: { type: Boolean, default: false },
    fontFamily: { type: String, default: "'Roboto', sans-serif" },
    isHidden: { type: Boolean, default: false },
    requiresManualTextToSpeech: { type: Boolean, default: false },
    hasGender: { type: Boolean, default: false },
    hasSingularOrPlural: { type: Boolean, default: false },
    hasPunctuation: { type: Boolean, default: false },
    // Used for local language support in scenarios like shorting and it store the reference of its own document or any other document in the same collection.
    // Ex: 'en': 'en', 'be': 'en' - Here 'en' as a value will be Object Id of the document with languageCode 'en'.
    localLanguage: {
      type: Schema.Types.ObjectId,
      ref: 'Language',
      default: null,
    },
    name: { type: String, required: true }, // INFO: This field is responsible for storing the name of the language in that particular language. ex: Hebrew should be entered as 'עברית'
    universalName: { type: String, required: true }, // INFO: This field is responsible for storing the name of the language in the english for the general use. ex: 'Hebrew' should be entered as 'Hebrew'
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const Language = mongoose.model<MongooseLanguage>(
  'Language',
  LanguageSchema
) as any;
