import { timeout } from '@/utils/helpers';

export const refreshAfter48Hours = () => {
  timeout(172800000).then(() => location.reload());
};

export const removeServiceWorker = () => {
  navigator?.serviceWorker?.getRegistrations().then(async (registrations) => {
    const names = await caches.keys();
    Promise.all([...names.map((n) => caches.delete(n)), ...registrations.map((r) => r.unregister())]).then(() => {
      console.log('refreshing...');
      // location.reload(true);
    });
  });
};
