// Utilities
import { make } from 'vuex-pathify';

const initialState = {
  type: null,
  mediaType: [],
  category: null,
  printable: null,
  audience: [],
};

const state = initialState;

const mutations = make.mutations(state);

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
