import axios from 'axios';
import { onBeforeUnmount } from '@vue/composition-api';
import { getCurrentLanguageWithoutWebsite, getCurrentWebsiteId } from '@/utils/helpers';

export const useAxios = (options) => {
  if (options.method === 'post' && options?.data) {
    options.data.lang = getCurrentLanguageWithoutWebsite();
    options.data.currentLangId = getCurrentWebsiteId(false);
  }

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const cancelToken = source.token;

  onBeforeUnmount(() => {
    source.cancel();
  });
  return axios({ ...options, cancelToken });
};
