import { requestContentApi } from './../services/api';
import { useCancellablePromise } from 'vue-composable';
import { loginApi, registerApi, changePasswordApi, recoveryAccountApi, echoApi } from '@/services/api';
import { timeout } from '@/utils/helpers';

export const useAuth = () => {
  const login = useCancellablePromise(
    ({ credential }) =>
      loginApi(credential).then(async (r) => {
        return r;
      }),
    { lazy: true }
  );

  const register = useCancellablePromise(
    ({ form }) =>
      registerApi(form).then(async (r) => {
        return r;
      }),
    { lazy: true }
  );

  const changePassword = useCancellablePromise(
    ({ form, token }) =>
      changePasswordApi(form, token).then(async (r) => {
        return r;
      }),
    { lazy: true }
  );

  const recoveryAccount = useCancellablePromise(
    ({ form }) =>
      recoveryAccountApi(form).then(async (r) => {
        return r;
      }),
    { lazy: true }
  );

  const echo = useCancellablePromise(
    () =>
      echoApi().then(async (r) => {
        return r;
      }),
    { lazy: true }
  );

  return { login, register, changePassword, recoveryAccount, echo };
};
