import { make } from 'vuex-pathify';

const state = {
  user: null,
  token: null,
  client: null,
  error: null,
  profile: {},

  // verifyRequired: false,
  // notifyOpen: false,
};
export const LS_DEFAULT_LANG_KEY = 'LS_DEFAULT_LANG_KEY';
export const LS_DEFAULT_WEBSITE_KEY = 'LS_DEFAULT_WEBSITE_KEY';
const mutations = {
  ...make.mutations(state),
  loginSuccess(state, { user, token }) {
    state.user = user;
    if (token) state.token = token;
    if (user?.lastSelectedClientId?._id) state.client = user?.lastSelectedClientId;
    else {
      state.client = null;
    }
    // localStorage.setItem(LS_DEFAULT_LANG_KEY, user?.preferences?.defaultLanguageCode);
    // localStorage.setItem(LS_DEFAULT_WEBSITE_KEY, user?.preferences?.defaultWebsiteCode);
  },

  updateAuth(state, { user, token }) {
    state.user = user;
    if (token) state.token = token;
    if (user?.lastSelectedClientId?._id) state.client = user?.lastSelectedClientId;
    else {
      state.client = null;
    }
  },
  updateClient(state, { client }) {
    state.client = client;
  },

  logout(state) {
    state.user = null;
    state.token = null;
    localStorage.removeItem(LS_DEFAULT_LANG_KEY);
    localStorage.removeItem(LS_DEFAULT_WEBSITE_KEY);
  },

  updateUserProfileCurrentBoardType(state, userCurrentBoardType) {
    state.profile.userCurrentBoardType = userCurrentBoardType;
  },
};

const actions = {
  login() {},

  loginSuccess({ state, commit }, { payload }) {
    commit('loginSuccess', { user: payload.user, token: payload.token });
  },

  updateAuth({ state, commit }, { payload }) {
    commit('loginSuccess', { user: payload.user, token: payload.token });
  },

  updateClient({ state, commit }, { payload }) {
    commit('updateClient', { client: payload.client });
  },

  logout({ state, commit }) {
    commit('logout');
  },

  updateUserProfileCurrentBoardTypeAction({ state, commit }, payload) {
    commit('updateUserProfileCurrentBoardType', payload.userCurrentBoardType);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
