import { environment } from '@/env';
import { forceUpdate } from '@/utils/helpers';

const defaultOptions = {
  store: null,
};

let options = { ...defaultOptions };

export const createApp = (appOptions) => {
  options = { ...defaultOptions, ...appOptions };
};

export const checkAutoPlaySupport = () => {
  const audio = new Audio(`${environment.cdn}/resources/sounds/autoplaysupport.mp3${forceUpdate()}`);

  // Attempt to play the audio asynchronously
  audio
    .play()
    .then(() => {
      console.log('Autoplay is supported');
    })
    .catch((error) => {
      const { store } = options;
      store?.dispatch({ type: 'app/updateAutoPlay', payload: { autoPlay: false } });
      console.log('Autoplay is NOT supported');
    });
};
