import Vue from 'vue';
import VueGtag from 'vue-gtag';
import { environment } from '@/env';
import { useState } from '@u3u/vue-hooks';
import { raiseGtagEvent, raiseGtagEventAtCustomPageLoad, raiseGtagEventAtLogin, timeout } from '@/utils/helpers';
import { AppMode } from '@/store/modules/app';
import { ref } from '@vue/composition-api';
import { userAuditLogs } from '@/services/api';
import { ACTION_TYPE_ENUM } from '../../../../packages/mongodb/src/user-audit-log';
import { StorageTypes, useStorage } from '@/services/storage';

export enum GtagEventName {
  activity_started = 'activity_started',
  login = 'cognishine_login',
  custom_page_load = 'custom_page_load',
  ping = 'ping',
  every_page_refresh = 'every_page_refresh',
}
export const intializeVueGtag = async () => {
  try {
    await timeout(1000);
    const { appMode } = useState('app', ['appMode']);
    const { user } = useState('auth', ['user']);
    const lastActivityTime = ref(Date.now());
    const eventsToListen = ['click', 'touchstart', 'keydown', 'scroll'];

    const isBrowserTabActive = ref(
      setInterval(() => {
        pingGtagEvent();
      }, 20000)
    );
    const envs = {
      prod: {
        [AppMode.clientView]: {
          id: 'G-1MS97MQKC3',
        },
        [AppMode.therapistsView]: {
          id: 'G-H9YVRZE7VE',
        },
      },
      ci: {
        [AppMode.clientView]: {
          id: 'G-2JKFH4WMD2',
        },
        [AppMode.therapistsView]: {
          id: 'G-0RSQB138FE',
        },
      },
    };

    const pingGtagEvent = () => {
      const { get } = useStorage();
      if (isBrowserTabActive.value && Date.now() - lastActivityTime.value < 300000) {
        raiseGtagEvent(GtagEventName.ping);
        if (user.value?._id)
          userAuditLogs({ user: user.value?._id, actionType: ACTION_TYPE_ENUM.PING, browserId: get(StorageTypes.BROWSER_ID) });
      }
    };

    const updateLastActivity = () => {
      lastActivityTime.value = Date.now();
    };

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        clearInterval(isBrowserTabActive.value);
        isBrowserTabActive.value = null;
      } else {
        isBrowserTabActive.value = setInterval(() => {
          pingGtagEvent();
        }, 20000);
      }
    });

    eventsToListen.forEach((event) => {
      document.addEventListener(event, () => {
        updateLastActivity();
      });
    });

    Vue.use(VueGtag, {
      enabled: false,
      config: envs[environment.env][appMode.value],
      onReady() {
        // ready
        raiseGtagEventAtCustomPageLoad(GtagEventName.custom_page_load);
        if (user.value) {
          raiseGtagEventAtLogin(GtagEventName.login);
        }
      },
    });
  } catch (err) {
    console.log({ err });
  }
};

export const startGoogleAnalyticsCollection = () => Vue.prototype.$gtag.optIn();

export const stopGoogleAnalyticsCollection = () => Vue.prototype.$gtag.optOut();
