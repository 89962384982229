import Vue from 'vue';

export const environment = {
  cdn: process.env.VUE_APP_CDN,
  version: process.env.VUE_APP_VERSION,
  env: process.env.VUE_APP_APP_ENV,
};

console.log({ environment });

Vue.prototype.$environment = environment;

// Vue.mixin({
//   data: function () {
//     return {
//       get $environment() {
//         return environment;
//       },
//     };
//   },
// });

// MyPlugin.install = function (Vue, options) {
//   // 1. add global method or property
//   Vue.myGlobalMethod = function () {
//     // some logic ...
//   }

//   // 2. add a global asset
//   Vue.directive('my-directive', {
//     bind (el, binding, vnode, oldVnode) {
//       // some logic ...
//     }
//     ...
//   })

//   // 3. inject some component options
//   Vue.mixin({
//     created: function () {
//       // some logic ...
//     }
//     ...
//   })

//   // 4. add an instance method
//   Vue.prototype.$myMethod = function (methodOptions) {
//     // some logic ...
//   }
// }
