import axios from 'axios';
import { useStorage, StorageTypes } from '@/services/storage';
import { useIubenda } from '@/use/use-iubenda';

import to from 'await-to-js';
import { environment } from '@/env';
import { autoLogoutOnBrowserClose, getClalitUserEmail, isClalitUser } from '@/utils/helpers';
import { GtagEventName, startGoogleAnalyticsCollection, stopGoogleAnalyticsCollection } from './gtag';
import { dataDogRumClearUser } from './data-dog-rum';

// import { reportAutoLogoutIssue } from '@/services/api';

const defaultOptions = {
  store: null,
  router: null,
  authRedirect: { path: '/login' },
  logoutRedirect: { name: 'home' },
  forbiddenRedirect: { path: '/403' },
  notFoundRedirect: { path: '/404' },
};

let options = { ...defaultOptions };

const { get, save, destroy } = useStorage();
const { isAllowedToCollectGoogleAnalyticsData } = useIubenda();

const loadFromStorage = () => {
  const [user, token] = [get(StorageTypes.USER), get(StorageTypes.TOKEN)];
  return user && token && { token, user };
};

const updateAuth = ({ token, user }) => {
  if (token) {
    if (autoLogoutOnBrowserClose(user)) {
      // Save in Sesstion storage
      save(StorageTypes.TOKEN, token, true);
    } else {
      save(StorageTypes.TOKEN, token);
    }
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  if (autoLogoutOnBrowserClose(user)) {
    // Save in Sesstion storage

    save(StorageTypes.USER, user, true);
  } else {
    save(StorageTypes.USER, user);
  }
  const { store } = options;
  store?.dispatch({ type: 'auth/updateAuth', payload: { token, user } });
};

export const logout = (redirectURL?: string | null) => {
  [
    StorageTypes.TOKEN,
    StorageTypes.USER,
    StorageTypes.IS_CLALIT_USER,
    StorageTypes.CLALIT_USER_EMAIL,
    StorageTypes.LANG_WHEN_CLALIT_LOGIN_OPENED,
    StorageTypes.USER_IDEA_TIME,
  ].forEach((i) => destroy(i));

  delete axios.defaults.headers.common.Authorization;

  const { store, router, authRedirect, logoutRedirect } = options;

  store?.dispatch({ type: 'auth/logout' });

  // INFO: clearing user info for datadog rum session.
  dataDogRumClearUser();

  // check cookie permission in local and start/stop google analytics based on it
  if (!isAllowedToCollectGoogleAnalyticsData()) stopGoogleAnalyticsCollection();
  if (isAllowedToCollectGoogleAnalyticsData()) startGoogleAnalyticsCollection();

  // if (router?.currentRoute?.meta?.requiresAuth) {
  //   // save(StorageTypes.REDIRECT_LOGIN, router?.currentRoute?.path);
  //   router.push(authRedirect);
  // }
  if (redirectURL) {
    router.push({ path: redirectURL });
  } else {
    router.push(logoutRedirect);
  }
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response: any) => {
    if (response?.config?.ignoreInterceptors) {
      return response;
    }

    const token = response?.headers['x-access-token'];
    if (token) {
      updateAuth({ token, user: response.data.user });
    }
    if (response?.headers['update-user-data'] === 'true') {
      updateAuth({ token: null, user: response.data.user });
    }
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    if (originalRequest?.ignoreInterceptors) {
      return Promise.reject(error);
    }

    const otpError = error.response?.data?.error === 'UNAUTHORIZEDOTP';

    if (otpError && error.response.status === 401 && !originalRequest._retry) {
      // if (isClalitUser() && getClalitUserEmail()) {
      //   setTimeout(() => logout('logout from clalit...'), 100);
      //   return;
      // }

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const otp = error.response?.headers['x-otp']; // window.localStorage.getItem('refreshToken');
      return new Promise(function (resolve, reject) {
        axios
          .post('/api/auth/authorize', {}, { headers: { Authorization: `Bearer ${otp}` } })
          .then((response) => {
            const token = response?.headers['x-access-token'];
            updateAuth({ token, user: response.data.user });

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            error.config.headers.Authorization = `Bearer ${token}`;

            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            processQueue(null, token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            console.log({ err });
            processQueue(err, null);
            if (401 === err?.response?.status && err?.response?.data?.error === 'UNAUTHORIZED') {
              console.log('LOGOUT!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! 1', {
                pageUrl: window.location?.href,
                user: loadFromStorage()?.user,
                token: loadFromStorage()?.token,
                place: 'LOGOUT 1',
                errMsg: err?.message,
                errStack: err?.stack,
              });
              // reportAutoLogoutIssue({
              //   pageUrl: window.location?.href,
              //   user: loadFromStorage()?.user,
              //   token: loadFromStorage()?.token,
              //   place: 'LOGOUT 1',
              //   errMsg: err?.message,
              //   errStack: err?.stack,
              // });
              setTimeout(() => logout(), 100);
            }
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    if (401 === error.response.status && error?.response?.data?.error === 'UNAUTHORIZED') {
      console.log('LOGOUT!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! 2', {
        pageUrl: window.location?.href,
        user: loadFromStorage()?.user,
        token: loadFromStorage()?.token,
        place: 'LOGOUT 2',
        errMsg: error?.response?.data?.error,
        errStack: error?.response?.data?.error,
      });
      // reportAutoLogoutIssue({
      //   pageUrl: window.location?.href,
      //   user: loadFromStorage()?.user,
      //   token: loadFromStorage()?.token,
      //   place: 'LOGOUT 2',
      //   errMsg: error?.response?.data?.error,
      //   errStack: JSON.stringify(error?.response),
      // });
      setTimeout(() => logout(), 100);
      return;
    }

    return Promise.reject(error);
  }
);

// axios.interceptors.response.use(
//   (response: any) => {
//     if (response?.config?.ignoreInterceptors) {
//       return response;
//     }

//     const token = response.headers['x-access-token'];
//     if (token) {
//       updateAuth({ token, user: response.data.user });
//     }
//     return response;
//   },
//   async (error) => {
//     if (error?.config?.ignoreInterceptors) {
//       return Promise.reject(error);
//     }

//     const status = error?.response?.status;
//     const otpError = error?.response?.data?.error === 'UNAUTHORIZEDOTP';
//     const accessError = error?.response?.data?.error === 'UNAUTHORIZED';

//     if (401 === status && otpError) {
//       if (promise) {
//         const [e1, r1] = await to(promise);

//         return axios.request(error.config);
//       }

//       promise = new Promise((reject, resolve) => {
//         const opt = error.response.headers['x-otp'];

//         // to(
//         axios({
//           url: `/api/auth/authorize`,
//           method: 'post',
//           headers: { Authorization: `Bearer ${opt}` },
//           ignoreInterceptors: true,
//         } as any)
//           .then((res) => {
//             const token = res.headers['x-access-token'];
//             if (token) {
//               updateAuth({ token, user: res.data.user });
//               // retry the request..

//               error.config.headers.Authorization = `Bearer ${token}`;

//               resolve();
//             }

//             resolve();
//           })
//           .catch((e) => {
//             setTimeout(() => logout(), 100);

//             reject();
//           });

//         // if (err) {
//         //   reject();
//         //   setTimeout(() => logout(), 100);
//         //   return;
//         //   // return Promise.reject(error);
//         // }

//         // if (!err) {
//         //   const token = res.headers['x-access-token'];
//         //   if (token) {
//         //     updateAuth({ token, user: res.data.user });
//         //     // retry the request..

//         //     error.config.headers.Authorization = `Bearer ${token}`;

//         //     resolve();
//         //   }
//         // }
//       });

//       const [e, r] = await to(promise);

//       promise = null;
//       // if ()

//       return axios.request(error.config);
//     }

//     if (401 === status && accessError) {
//       setTimeout(() => logout(), 100);
//     }

//     return Promise.reject(error);
//   }
// );

export const createAuth = (authOptions) => {
  options = { ...defaultOptions, ...authOptions };

  const payload = loadFromStorage();
  if (payload) {
    updateAuth(payload);
  }

  const { router, store, authRedirect } = options;

  router?.beforeEach((to, from, next) => {
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
      return next();
    }

    const token = store.get('auth/token');
    if (token) {
      return next();
    }

    const redirectTo = to.fullPath;
    save(StorageTypes.REDIRECT_LOGIN, redirectTo);
    return next(authRedirect);
  });
};
