import { Model } from '@vuex-orm/core';
import { BOARD_TYPE } from '@packages/mongodb';

export class Board extends Model {
  public static entity = 'board';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(''),
      user: this.attr(null),
      client: this.attr(null),
      organization: this.attr(null),
      items: this.attr([]),
      boardType: this.attr(BOARD_TYPE.myBoard),
    };
  }
}
