import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import hooks from '@u3u/vue-hooks';

Vue.use(hooks);
Vue.use(VueCompositionAPI);

// declare module '@vue/composition-api' {
//   interface SetupContext {
//     readonly refs: { [key: string]: Vue | Element | Vue[] | Element[] | HTMLElement };
//   }
// }
