// import languages from '@/data/i18n/languages.json';

import kebabCase from 'lodash/kebabCase';
import { getLanguages } from '@/data/i18n/languages';
import { LS_DEFAULT_WEBSITE_KEY } from '@/store/modules/auth';

interface Meta {
  requiresAuth?: boolean;
  hasToolbar?: boolean;
  hasDrawer?: boolean;
  hasFooter?: boolean;
  hasSystemNotification?: boolean;
  keepAlive?: boolean;
  cacheTo?: any;
  hasClientCue?: boolean;
}

export function getLanguageCookie() {
  return typeof document === 'undefined' ? undefined : 'en-us';
  // : new Map(
  //     document.cookie.split('; ').map(c => c.split('='))
  // ).get('currentLanguage')
}

export function layout(path, name, children) {
  const folder = kebabCase(name);

  return {
    path,
    component: () => import(/* webpackChunkName: "layout-[request]" */ `@/layouts/${folder}/index`),
    props: true,
    children,
  };
}

export function root(children) {
  const regexForAvailableLangAndWebsite = `${getLanguages()
    .map((el) => el.locale)
    .join('|')}`;

  return [layout(`/:lang(${regexForAvailableLangAndWebsite})`, 'root', children)];
}

export function redirect(redirect) {
  return { path: '*', redirect, props: true };
}

export function redirectLang(path = '') {
  const userDefaultWebsiteCode = getLanguages()?.find((lang) => String(lang._id) === String(localStorage.getItem(LS_DEFAULT_WEBSITE_KEY)))
    ?.locale;

  const fallbackLocale = getLanguages()?.find((lang) => lang.language.fallback === true).locale;

  return redirect((to) => {
    const lang = `/${userDefaultWebsiteCode || fallbackLocale}`;
    return `${lang}${path || to.path}`;
  });
}

export function route(path, name, meta?: Meta, bE = null) {
  return {
    component: () => import(/* webpackChunkName: "page-[request]" */ `@/pages/${name}/index`),
    name,
    path,
    ...(meta && { meta }),
    ...(bE && { beforeEnter: bE }),
    props: true,
  };
}

export const nestedRoute = (path, name, meta: Meta = null, bE = null, children = []) => {
  const childArr = children.map((el) => {
    let tmpMeta = {};
    if (el.meta) {
      tmpMeta = { ...el.meta };
    }
    if (meta) {
      tmpMeta = { ...tmpMeta, ...meta };
    }

    if (Object.keys(tmpMeta).length === 0) {
      tmpMeta = null;
    }

    return {
      component: () => import(/* webpackChunkName: "page-[request]" */ `@/pages/${name}/${el.name}/index/`),
      path: el.path,
      name: el.name,
      meta: tmpMeta,
      ...(el.beforeEnter && { beforeEnter: el.beforeEnter }),
      props: true,
    };
  });

  return {
    component: () => import(/* webpackChunkName: "page-[request]" */ `@/pages/${name}/index/`),
    path,
    name,
    ...(meta && { meta: meta }),
    ...(bE && { beforeEnter: bE }),
    ...(childArr.length && { children: childArr }),
    props: true,
  };
};

export function routesForDocs(pages) {
  const result = pages.map((p) => ({
    component: () => import(/* webpackChunkName: "page-[request]" */ `@/pages/docs/index`),
    name: p,
    path: p,
  }));
  return result;
}

export function redirectTo(path, url) {
  // language regex:
  // /^[a-z]{2,3}(?:-[a-zA-Z]{4})?(?:-[A-Z]{2,3})?$/
  // /^[a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3}$/
  const languageRegex = /^\/([a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3})(?:\/.*)?$/;
  const fallbackLocale = getLanguages()?.find((lang) => lang.language.fallback === true).locale;

  return {
    path,
    redirect: (to) => {
      let lang = `/${getLanguageCookie() || fallbackLocale}`;

      if (!languageRegex.test(lang)) {
        lang = `/${fallbackLocale}`;
      }
      return `${lang}/${url}`;
    },
  };
}

export function redirectNotFound() {
  // language regex:
  // /^[a-z]{2,3}(?:-[a-zA-Z]{4})?(?:-[A-Z]{2,3})?$/
  // /^[a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3}$/
  const languageRegex = /^\/([a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3})(?:\/.*)?$/;
  const fallbackLocale = getLanguages()?.find((lang) => lang.language.fallback === true).locale;

  return {
    path: '*',
    redirect: (to) => {
      let lang = `/${getLanguageCookie() || fallbackLocale}`;

      if (!languageRegex.test(lang)) {
        lang = `/${fallbackLocale}`;
      }

      const x = `${lang}/404`;
      return x;
    },
  };
}
