<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script lang="ts">
import { watch } from '@vue/composition-api';
import { usePageVisibility, setI18n, useI18n } from 'vue-composable';
import { useState } from '@u3u/vue-hooks';
import moment from 'moment';

import { refreshAfter48Hours, removeServiceWorker } from './services/app';
import { environment } from './env';

import enUs from './lang/en-us';
import heIl from './lang/he-il';
import arIl from './lang/ar-il';
import deDe from './lang/de-de';
import esEs from './lang/es-us';
import beUk from './lang/be-uk';
import ruIl from './lang/ru-il';
import trDe from './lang/tr-de';

import { getLanguages } from './data/i18n/languages';

import { useAuth } from './use/use-auth';

export default {
  setup() {
    const languageWithItsTranslation = {
      en: enUs,
      he: heIl,
      ar: arIl,
      de: deDe,
      es: esEs,
      be: beUk,
      ru: ruIl,
      tr: trDe,
    };

    const getLangMessagesObject = () => {
      const messages = {};
      getLanguages()?.map((language) => {
        messages[language.locale] = languageWithItsTranslation[language.language.languageCode];
      });

      return messages;
    };

    setI18n({
      legacy: false,
      locale: 'en-us',
      fallback: 'en-us',
      messages: getLangMessagesObject() as any,
    });

    const { version } = useState('app', ['version']);
    const { user } = useState('auth', ['user']);

    console.log(`--- ${version.value} ---`);

    removeServiceWorker();

    refreshAfter48Hours();

    // const ensureVersion = async () => {
    //   const response = await axios.get('/health');
    //   if (!response || typeof response.data !== 'string') {
    //     return;
    //   }
    //   if (version.value === response.data) return;

    //   console.log('need update');

    //   removeServiceWorker();
    // };

    let startTime = moment();

    const { visibility, hidden } = usePageVisibility();

    const { echo } = useAuth();

    watch(
      () => hidden.value,
      (v) => {
        // if (process.env.NODE_ENV !== 'production') {
        //   return;
        // }

        if (v) {
          return;
        }

        console.log({ environment });

        if (user.value) {
          echo.exec();
          // axios.post(`/api/auth/echo`).catch((e) => {});
        }

        if (hidden.value) {
          startTime = moment();
          return;
        }
        const hours = moment.duration(moment().diff(startTime)).asHours();

        if (hours < 1) {
          return;
        }

        // ensureVersion();
      }
    );

    // if (process.env.NODE_ENV === 'production') {
    //   ensureVersion();
    // }
    return {};
    // const dotest = async () => {
    //   const t = await axios({ url: 'http://localhost:3000/api/test2', method: 'GET' });

    //   console.log({ t });
    // };

    // return { dotest, login };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
  min-height: 100svh;
}
</style>
