import Vue from 'vue';
import VueRouter from 'vue-router';
// import scrollBehavior from './scroll-behavior';
import { redirectLang, route, root, layout, routesForDocs, nestedRoute } from './util';
import { querystring } from './query-guard';
import { useStore } from '@u3u/vue-hooks';
import { LS_DEFAULT_LANG_KEY, LS_DEFAULT_WEBSITE_KEY } from '@/store/modules/auth';
import {
  isUserHasOrgAndUserNotHasOrgViewerRoleGuard,
  isUserHasBasicOrProPlanGuard,
  isUserHasProPlanGuard,
  isUserNavigatingWithClientContext,
  isNotPrivateWebsiteGaurd,
  isUserHasContentManagementPageAccessGuard,
  isUserNotLoggedInGuard,
} from './nav-guards';
import { getLanguages } from '@/data/i18n/languages';
import { AppMode, clientViewPages } from '@/store/modules/app';

Vue.use(VueRouter);

let popStateDetected = false;
const withoutLanguageAndWebsiteAllowedPaths = ['/saml-auth'];

export function createRouter() {
  window.addEventListener('popstate', (e) => {
    popStateDetected = true;
  });

  const routes = root([
    layout('saml-auth', 'saml-auth', []),

    layout('home-practice/:homePracticeId', 'home-practice', [route('', 'home-practice')]),

    layout('', 'Frontend', [
      route('', 'home'),
      // redirectTo('domains/speech', 'domains/speech/articulation'),
      route('domains/speech/articulation', 'articulation'),
      route('domains/:domain', 'domains'),
      route('explore-activities', 'subdomains'),
      route('domains/:domain/:subdomain/:activity', 'activity'),
      route('login', 'login', null, isUserNotLoggedInGuard),
      route('clalit', 'clalit-login', { hasClientCue: false }),
      route('register', 'register', null, isUserNotLoggedInGuard),
      route('register-successful', 'register-successful'),
      route('account-password', 'account-password'),
      route('account-recovery', 'account-recovery'),
      route('verified', 'verified'),
      route('purchased', 'purchased'),
      route('purchase-cancelled', 'purchase-cancelled'),
      route('purchase-failed', 'purchase-failed'),
      route('boards', 'boards', { requiresAuth: true }),
      route('boards/:board', 'board', { requiresAuth: true }),
      route('pricing', 'pricing', null, isNotPrivateWebsiteGaurd),
      route('pricing/:plan', 'plan', { requiresAuth: true }, isNotPrivateWebsiteGaurd),
      route('profile', 'profile', { requiresAuth: true }),
      route('contact-us', 'contact-us'),
      route('version', 'version'),
      route('browse-subjects', 'browse-subjects'),
      route('free-activities', 'free-activities'),
      route('faq', 'faq'),
      route('survey/:survey', 'survey'),
      route('content-management', 'content-management', { requiresAuth: true }, isUserHasContentManagementPageAccessGuard),
      route('domains/speech/phonetics', 'phonetics-words'),
      route('domains/speech/test-muuri', 'test-muuri'),
      route('domains/speech/test-vuuri', 'test-vuuri'),
      route('domains/speech/test-muuriv', 'test-muuriv'),
      route('test-muuri-scroll', 'test-muuri-scroll'),
      route('codepen-muuri-scroll', 'codepen-muuri-scroll'),
      route('domains/user-activity/:activity', 'user-defined-activity'),
      // organization routes

      nestedRoute(
        'organization',
        'organization',
        { requiresAuth: true, hasClientCue: false },
        isUserHasOrgAndUserNotHasOrgViewerRoleGuard,
        [
          { name: 'organization-general', path: 'general', beforeEnter: isUserHasBasicOrProPlanGuard },
          { name: 'organization-user', path: 'user', beforeEnter: isUserHasBasicOrProPlanGuard },
          { name: 'organization-client', path: 'client', beforeEnter: isUserHasProPlanGuard },
          { name: 'organization-report', path: 'report', beforeEnter: isUserHasBasicOrProPlanGuard },
          { name: 'organization-plan-billing', path: 'plan-billing', beforeEnter: isUserHasBasicOrProPlanGuard },
        ]
      ),

      route('accept-org-invitation', 'accept-org-invitation'),
      route('saml-login', 'saml-login', { requiresAuth: true }),

      route('tmp-user-feedback', 'tmp-user-feedback'),

      route('self-practice-dashboard', 'self-practice-dashboard'),

      route('admin-dashboard-statistics', 'admin-dashboard-statistics'),

      route('tmp-base-tile-grid', 'tmp-base-tile-grid', { requiresAuth: true, hasClientCue: false }),
      // client dashboard routes

      nestedRoute('client', 'client', { requiresAuth: true, hasClientCue: false }, isUserNavigatingWithClientContext, [
        // { name: 'client-dashboard-info', path: 'info' },
        { name: 'client-dashboard', path: 'dashboard' },
        // { name: 'client-dashboard-async-practices', path: 'async-practices' },
        // { name: 'client-dashboard-sync-practices', path: 'sync-practices' },
      ]),

      // client dashboard routes

      ...routesForDocs(['privacy', 'terms', 'printing-faq', 'professional-tips']),

      route('error', 'error'),
      route('404', '404'),

      // redirectNotFound(),
      redirectLang('/404'),
    ]),

    layout('/redirect/:token', 'Blank', [route('verified', 'verified')]),

    // redirectLang(),
  ]) as any;

  const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (!(to.name === 'subdomains' && from.name === 'activity')) return { x: 0, y: 0 };
    },
  });

  router.beforeEach((to, from, next) => {
    const userDefaultLanguageCode = localStorage.getItem(LS_DEFAULT_LANG_KEY);
    const userDefaultWebsiteCode = getLanguages()?.find(
      (lang) => String(lang._id) === String(localStorage.getItem(LS_DEFAULT_WEBSITE_KEY))
    )?.locale;
    const fallbackLocale = getLanguages()?.find((lang) => lang.language.fallback === true).locale;
    const targetLang = to.params?.lang;
    let redirectLang = targetLang ? targetLang : from.params?.lang ? from.params.lang : fallbackLocale;
    let upadateToPath = to.path;

    //if no lang was specified and the user has a default lang setup - change lang to user's default
    if (userDefaultWebsiteCode && !targetLang && !from.params?.lang) redirectLang = userDefaultWebsiteCode;

    //check if the requested language is not found
    if (!getLanguages()?.find((lang) => lang.locale === redirectLang)) {
      //remove language from the path and set the target lang to null
      upadateToPath = to.path.replace('/' + targetLang, '');
      to.params.lang = null;
      redirectLang = fallbackLocale;
    }
    to.meta.popStateDetected = popStateDetected;
    if (to?.meta?.popStateDetected) {
      if (to?.params?.lang !== from?.params?.lang) {
        router.go(0);
      }
    }
    popStateDetected = false;

    //set the target language if path doens't start with redirect
    if (withoutLanguageAndWebsiteAllowedPaths.includes(to.path)) {
      const nextConfig = { path: '/' + redirectLang + to.path };
      if (Object.keys(to.query).length) nextConfig['query'] = to.query;
      next(nextConfig);
    } else if (to.params.lang == null && !to.path.startsWith('/redirect')) {
      to.params.lang = redirectLang;

      const nextConfig = { path: '/' + redirectLang + to.path };
      if (Object.keys(to.query).length) nextConfig['query'] = to.query;

      next(nextConfig);
    } else {
      if (!to.path.startsWith('/redirect')) to.params.lang = redirectLang;

      next();
    }
  });

  router.afterEach((to, from) => {
    const store = useStore() as any;
    if (clientViewPages.includes(to?.name)) {
      (store.value as any).set('app/appMode', AppMode.clientView);
    } else {
      (store.value as any).set('app/appMode', AppMode.therapistsView);
    }
  });
  // router.beforeEach(querystring);

  return router;
}
