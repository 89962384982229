import Vue from 'vue';
import Vuex, { Store, Plugin } from 'vuex';
import VuexORM from '@vuex-orm/core';

import * as fromEntities from './entities';

import app from './modules/app';
import auth from './modules/auth';
import language from './modules/language';
import subdomains from './modules/subdomains';
import pathify from 'vuex-pathify';

Vue.use(Vuex);

export function createStore() {
  const database = new VuexORM.Database();
  Object.keys(fromEntities).forEach((entity) => database.register(fromEntities[entity]));

  return new Vuex.Store({
    modules: {
      app,
      auth,
      subdomains,
      language
    },
    plugins: [VuexORM.install(database), pathify.plugin],
  });
}
