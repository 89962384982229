import { getCurrentLanguageWithoutWebsite } from '@/utils/helpers';
import { ref, computed } from '@vue/composition-api';
import { useI18n } from 'vue-composable';

export const useSubdomain = ({ subdomainId }) => {
  const { i18n } = useI18n();
  const domains = i18n.value.cognishine.domains;
  const subdomain = computed(() =>
    Object.values(domains)
      .map((d) => Object.values(d['types'][getCurrentLanguageWithoutWebsite()]))
      .flat()
      .find((t) => t['key'].toLocaleLowerCase() === subdomainId.value.toLocaleLowerCase())
  );
  return { subdomain };
};
