import { ref } from '@vue/composition-api';
import { useCancellablePromise } from 'vue-composable';
import { getAllCategory, getAllDomains, getAllDomainsTypes, getAllRoles, getAllSubcategory } from '../services/api';
import { ActivityType, Category, Domain, Role, SubCategory } from '../store/entities';

const loaded = ref(false);

export const useMetadata = () => {
  const loadMetadata = useCancellablePromise(
    () => {
      if (loaded.value) {
        return;
      }
      console.log('loading metadata');
      loaded.value = true;
      return Promise.all([getAllDomains(), getAllDomainsTypes(), getAllCategory(), getAllSubcategory()]).then(async (r) => {
        const [domains, types, category, subcategory] = r;

        await Promise.all([
          Domain.insertOrUpdate({ data: domains.data }),
          ActivityType.insertOrUpdate({ data: types.data }),
          Category.insertOrUpdate({ data: category.data }),
          SubCategory.insertOrUpdate({ data: subcategory.data }),
        ]);

        return r;
      });
    },
    { lazy: true }
  );

  return { loadMetadata };
};
