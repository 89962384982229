let languages = null;

export const setLanguages = (lang = []) => {
  languages = lang;
};

export const getLanguages = () => {
  return languages;
};


console.log("languages.value : ", languages);