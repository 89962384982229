import { Model } from '@vuex-orm/core';

export class Plan extends Model {
  public static entity = 'plan';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(''),
      price: this.attr(null),
      type: this.attr(null),
      recommended: this.attr(false),
      licenses: this.attr(null),
      requires: this.attr(null),
      paypalPlanId: this.attr(null),
      order: this.attr(0),
    };
  }
}
