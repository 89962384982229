import { DateRangePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { ChartPlugin } from '@syncfusion/ej2-vue-charts';
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import { SliderPlugin } from '@syncfusion/ej2-vue-inputs';
import { registerLicense } from '@syncfusion/ej2-base';
import { QRCodeGeneratorPlugin } from '@syncfusion/ej2-vue-barcode-generator';

import Vue from 'vue';

export function createSyncfusion() {
  Vue.use(GridPlugin);
  Vue.use(ChartPlugin);
  Vue.use(SliderPlugin);
  Vue.use(DateRangePickerPlugin);
  Vue.use(QRCodeGeneratorPlugin);
  registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFac1lJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkJhXX9WdHJRQmlZU0w=');
}
