import { make } from 'vuex-pathify';

const state = {
  languages: null,
};
const mutations = {
  ...make.mutations(state),

  updateLanguage(state, { languages }) {
    state.languages = languages;
  },
};

const actions = {
  updateLanguage({ state, commit }, { payload }) {
    commit('updateLanguage', { languages: payload.languages });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
