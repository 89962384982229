import { Model } from '@vuex-orm/core';

export class SurveyResults extends Model {
  public static entity = 'survey-results';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      surveyId: this.attr(null),
      surveyHeader: this.attr(null),
      surveyName: this.attr(null),
      userId: this.attr(null),
      userEmail: this.attr(null),
      questionId: this.attr(null),
      questionText: this.attr(null),
      questionType: this.attr(null),
      questionAnswerValue: this.attr(null),
      questionAnswerAdditionalText: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    };
  }
}
